import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
import ScrambleTextPlugin from '../lib/ScrambleTextPlugin.min';
import SplitText from '../lib/SplitText.min';
import DrawSVGPlugin from '../lib/DrawSVGPlugin.min';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, ScrambleTextPlugin, SplitText, DrawSVGPlugin);

import {p5Line} from './p5Line.js';
import {p5Line_wh} from './p5Line_wh.js';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
	}
};

var winH = $(window).height();
var winW = $(window).width();
var body = $('body');


function nav_show(){
	body.addClass('navopen');
	navlistAnime();
}
function nav_hide(){
	body.removeClass('navopen');
}
function navInit(){
	nav_current();
	$('#nav_btn').on('click', function(){
		if( body.hasClass('navopen') ){
			nav_hide();
		}else{
			nav_show();
		}
	});
}

function navlistAnime (){
	let menuli = gsap.utils.toArray('#main_nav li');
	menuli.forEach((el, i) => {
		gsap.from(el, {x:60,opacity:0, duration:0.6, delay:i*0.05+0.3})
	});
}

var nav = $("#nav");
var $navLi = nav.find("li");
function nav_current(){
	$navLi.removeClass("on");
}

function singleSlider (){
	$('.single_swiper').each(function(){
		const swiper = new Swiper(this, {
			slidesPerView: 'auto',
			speed: 1000,
			spaceBetween: 10,
			loop: true,
			autoHeight: true,
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			navigation: {
				prevEl: ".single-swiper-button-prev",
				nextEl: ".single-swiper-button-next",
			},
			breakpoints: {
				768: {
					spaceBetween: 30,
				}
			}
		});
	});
}


const setVH_Height = () => {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', vh+'px');
}
const setVH = () => {
	const navvh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--kvvh', navvh+'px');
	const vw = window.innerWidth * 0.01;
	document.documentElement.style.setProperty('--vw', vw+'px');
}
setVH_Height();
setVH();
window.addEventListener('resize', function () {
	if(window.matchMedia('(min-width:768px)').matches){
		setVH_Height();
	}
	setVH();
});


var scrollSmoother;
function smoothscrollFunc(){
	gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);
	scrollSmoother = ScrollSmoother.create({
		smooth: 1.2,
		effects: true,
		SmoothTouch : 0.1,
		ignoreMobileResize: true,
		normalizeScroll: true,
	});
	navWhite();
	txtanim();
	if(window.matchMedia('(max-width:767px)').matches){
		sp_banner_lag();
	}else{
		single_sticky();
	}
}
function lineshowFunc(){
	const lineelm = document.getElementById('lineAnimation');
	gsap.utils.toArray('.lineshow').forEach(target => {
		let scrollTrigger = ScrollTrigger.create({
			trigger: target,
			start: "top top+=10%",
			end: "bottom bottom-=10%",
			onToggle: self => {
				if(self.isActive){
					gsap.to(lineelm,{
						opacity:1,
						duration: 0.2,
					});
					// body.addClass('lineshow');
				}else{
				}
			},
			onUpdate: self => {
			}
		});
	});
}

let EL_scrollTrigger;
function elFunc(EL){
	gsap.utils.toArray(EL).forEach(target => {
		if(window.matchMedia('(max-width:767px)').matches){
			if(target.classList.contains('nosp')){
				return;
			}
		}
		if(!target.classList.contains('nomove')){
			target.style.opacity = 0;
		}
		EL_scrollTrigger = ScrollTrigger.create({
			trigger: target,
			start: "top bottom",
			once: true,
			onEnter: self => {
				elAnime(target);
			},
		});
	});
}

function elAnime(target){
	target.style.opacity = 1;
	if(target.classList.contains('elp')){
		var elc = target.querySelectorAll('.elc');
	}else{
		var elc = [target];
	}
	elc.forEach((elc, i) => {
		let _x = 120;
		let _opa = 0;
		if(elc.classList.contains('elbtn')){
			_x = 0;
		}
		if(elc.classList.contains('nomove')){
			_x = 0;
			_opa = 1;
		}
		gsap.fromTo(elc,{x:_x,opacity:_opa},{
			x: 0,
			opacity:1,
			duration: 0.6,
			delay: i * 0.1,
			ease:'power4',
			onStart: function(){
				const _typing = elc.querySelectorAll('.typing');
				if(_typing){
					_typing.forEach(el => {
						el.style.display = 'none';
					});
				}
			},
			onComplete: function(){
				const _txef = elc.querySelectorAll('.txef');
				if(_txef){
					txtEffect(_txef);
				}
				const _typing = elc.querySelectorAll('.typing');
				if(_typing){
					typingEffect(_typing);
				}
				elc.classList.add('elon');
			}
		});
	});
}

function hoverShuffleFunc(){
	gsap.utils.toArray('a').forEach(target => {
		var _txef = target.querySelectorAll('.txef');
		if(_txef){
			target.addEventListener('mouseenter', function() {
				gsap.utils.toArray(_txef).forEach(el => {
					if(!el.classList.contains('nohover')){
						txtEffect(_txef);
					}
				});
			});
		}
	});	
}

function txtEffectInit (txef){
	gsap.utils.toArray(txef).forEach(el => {
		if(window.matchMedia('(max-width:767px)').matches){
			if(el.classList.contains('nosp')){
				return;
			}
		}
		const txt = el.textContent;
		el.dataset.txt = txt;
		el.style.opacity = 0;
	});
	gsap.utils.toArray('.typing').forEach(el => {
		el.style.display = 'none';
	});
}

function txtEffect (target){
	gsap.utils.toArray(target).forEach(el => {
		const txt = el.dataset.txt;
		el.style.opacity = 1;
		gsap.to(el, {duration: 0.3, scrambleText: {text:txt, chars:"upperCase", revealDelay:0.3, speed:2}});
	});
}

function typingEffect(target){
	gsap.utils.toArray(target).forEach(el => {
		const letters = el.textContent.split("");
		el.textContent = "";
		let i = 0;
		function typeWriter() {
			el.style.display = 'inline';
			if (i < letters.length) {
				const span = document.createElement("span");
				span.textContent = letters[i];
				el.append(span);
				i++;
				setTimeout(typeWriter, 100);
			}
		}
		setTimeout(typeWriter, 1000);
	});
}

function triggerLine(){
	const trigger_line = document.getElementById('trigger_line');
	if(trigger_line){
		trigger_line.style.opacity = 0;
		let scrollTrigger = ScrollTrigger.create({
			trigger: trigger_line,
			start: "top bottom",
			once: true,
			onEnter: self => {
				trigger_line.style.opacity = 1;
				gsap.fromTo('#trigger_line path', {strokeDasharray:'0,3000', opacity:1}, {strokeDasharray:'3000,3000', duration:2, delay:1});
			}
		});
	}
}

function slitFunc(){
	gsap.to('.slitborder', {
		y: -50,
		scrollTrigger: {
			trigger: '.slit',
			start: 'top bottom',
			end: 'bottom top',
			scrub: 0.5,
		}
	})
}

async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


var tar_offset;
function pageScroll(){
	if(window.matchMedia('(max-width:767px)').matches){
		tar_offset = 0;
	}else{
		tar_offset = 0;
	}
	$('a.anchor').on('click', function(){
		nav_hide();
		let href = $(this).data('tag');
		let target = href === "#" || href === "" ? 'html' : href;
		gsap.to(window, { duration:0.6, scrollTo:{y:target, autoKill:true, offsetY:tar_offset}, ease:'power2'});
	});
	$('#scrldown').on('click', function(){
		gsap.to(window, { duration:0.6, scrollTo:{y:'.scrltarget', offsetY:tar_offset}, ease:'power2'});
	});
	$('#btt').on('click', function(){
		gsap.to(window, { duration:0.6, scrollTo:{y:0}, ease:'power2'});
	});
}


function expandFunc() {
	let maincontents = document.getElementById('maincontents');
	let exbtn = document.querySelectorAll('.exbtn');
	const exopen = (parent) => {
		maincontents.classList.add('exopen');
		parent.classList.add('open');
		const exc = parent.querySelectorAll('.exc');
		gsap.utils.toArray(exc).forEach(ex => {
			const ex_el = ex.querySelectorAll('.el');
			gsap.utils.toArray(ex_el).forEach(obj => {
				obj.style.opacity = 0;
			});
			const ex_tx = ex.querySelectorAll('.txef');
			gsap.utils.toArray(ex_tx).forEach(tx => {
				tx.style.opacity = 0;
			});
			const _typing = ex.querySelectorAll('.typing');
			if(_typing){
				_typing.forEach(el => {
					el.style.display = 'none';
				});
			}	
			gsap.to(ex,{
				height:'auto',
				duration:0.3,
				ease: "power2.inOut",
				onComplete:function(){
					const exc_el = ex.querySelectorAll('.el');
					elFunc(exc_el);
				}
			});
		});
	}
	const exclose = (parent) => {
		maincontents.classList.remove('exopen');
		parent.classList.remove('open');
		const exc = parent.querySelectorAll('.exc');
		gsap.utils.toArray(exc).forEach(ex => {
			gsap.to(ex,{
				height: 0,
				duration:0.3,
				ease: "power2.inOut",
				onComplete:function(){
					// elFunc('.el');
					ScrollTrigger.refresh();
				}
			});
		});
	}
	for (let i = 0; i < exbtn.length; i++) {
		exbtn[i].addEventListener('click',function(){
			let parent = this.parentElement;
			const result = parent.classList.contains('open');
			if (result) {
				exclose(parent);
			}else{
				exopen(parent);
			}
		});
		if(exbtn[i].classList.contains('already_open')){
			let parent = exbtn[i].parentElement;
			exopen(parent);
		}
	}
}


function videoSwitch() {
	var videoelm = document.getElementById('video');
	if(!videoelm) {
		return false;
	}
	var videosp = '_sp.';
	var videopc = '_pc.';
	const src = videoelm.getAttribute('src');
	if(window.matchMedia('(max-width:767px)').matches){
		if(src.includes(videopc)){
			var srcname = videoelm.getAttribute('src').replace(videopc, videosp);
			videoelm.src = srcname;
		}
	}else{
		if(src.includes(videosp)){
			var srcname = videoelm.getAttribute('src').replace(videosp, videopc);
			videoelm.src = srcname;
		}
	}
	videoelm.style.opacity = 1;
}


function loading(){
	const loader = document.getElementById('loader');
	if(sessionStorage.getItem('loader')){
		loader.style.display = 'none';
		return false;
	}
	const resources = document.querySelectorAll('img');
	const total = resources.length;
	let loaded = 0;
	gsap.set('#loadingLine path', {drawSVG:'0%'});
	loadingLine.style.opacity = 1;

	function loadImage(src) {
		return new Promise((resolve, reject) => {
			const startTime = new Date();
			const img = new Image();
			img.src = src;
			img.addEventListener('load', () => {
				loaded++;
				const percent = Math.floor((loaded / total) * 100)+'%';
				gsap.to('#loadingLine path', {drawSVG:percent, duration: 0.1, ease:'Expo.easeIn'})
				var endTime = new Date();
				var elapsedTime = endTime - startTime;
				if (elapsedTime < 50) {
					setTimeout(() => {
						resolve();
					}, 50 - elapsedTime);
				}else{
					resolve();
				}
			});
		})
	}
	function showloader() {
		loader.style.display = 'block';
		loader.style.opacity = 1;
	}
	function hideloader() {
		gsap.to('#loader',{autoAlpha:0,display:"none", duration: 1})
	}
	async function loadResources(resources) {
    try{
			showloader();
			for(const v of resources){
				await loadImage(v.src);
			}
			console.log("END");
			setTimeout(() => {
				hideloader();
				sessionStorage.setItem('loader', true);	
			}, 500);
    } catch(err){
      console.log(err);
    }
	}	
	loadResources(resources);
}


var PageEvents = {
	loadFunc: function(){
		loading();
		navInit();
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		winH = $(window).height();
		winW = $(window).width();	
		txtEffectInit('.txef');
		elFunc('.el');
		hoverShuffleFunc();
		expandFunc();
		triggerLine();
		pageScroll();
		OBSERVER.observeEvents(1);
	},
	pjaxReadyFunc: function(){
	},
	topFunc: function(){
		body.addClass('top');
		videoSwitch();
		window.addEventListener('resize', function () {
			videoSwitch();
		});		
		p5Line();
		// p5Line_wh();
		lineshowFunc();
		slitFunc();
	},
	singleFunc: function(){
		singleSlider();
	}
};

function pjaxSettings(){

	barba.use(barbaPrefetch);

	const replaceBogo = function(data){
		const $newPageBogo = $(data.next.html).find('#bogo').html();
		body.find('#bogo').html($newPageBogo);
	}

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}
	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if(href == null){
			href = el.getAttribute('xlink:href');
		}
		if(el.classList.contains('nopj')){
			return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (!href.startsWith(site_url)) {
			el.setAttribute('target','_blank');
			return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/,"");
		if (href.startsWith(location.protocol + '//' + location.host)) {
			if (href.indexOf('#') > -1 && extract_hash != url ){
				return false;
			}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
			el.setAttribute('target','_blank');
			return true;
		}
	}

	async function scroll() {
		await new Promise((resolve, reject) => {
			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0.6, scrollTo:target, ease:'power2', onComplete:function(){
						resolve();
					}});
				}else{
					gsap.to(window, { duration:0.6, scrollTo:0, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0.6, scrollTo:0, ease:'power2', onComplete:function(){
					resolve();
				}});
			}
		})
	}

	var $footerelm = $('footer')
	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage topready navopen navwh');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		replaceBogo(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});
	barba.hooks.after((data) => {
		async function callerFun(){
			await scroll();
			pageCheck();
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onComplete:function(){
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

function pageCheck(){
	PageEvents.commonFunc();

	if( document.getElementById('top') ){
		PageEvents.topFunc();
	}else{
		body.addClass('inpage');
	}
	if( document.getElementById('single') ){
		PageEvents.singleFunc();
	}
	body.addClass('ready');
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
	});
}

initialize();

})();
})( jQuery );

}