import p5 from 'p5';

let myp5;

export function p5Line() {

const p5init = ( p ) => {
	var _windowW = p.windowWidth;
	var _windowH = p.windowHeight;
	var _cw = p.windowWidth;
	var _ch = p.windowHeight;

	var _minx = -_cw*1.5;
	var _maxx = _cw*2.0;
	var _miny = 0;
	var _maxy = _ch;

	var _sy1 = 0.1,
			_sy2 = 0.9,
			_ey1 = 0.1,
			_ey2 = 0.9;
	var _sy = [0.1, 0.2, 0.3, 0.4];
	var _ey = [0.6, 0.7, 0.8, 0.9];
	var _startX = -10;
	var _endX = _windowW+10;
	var _pointInit = [_windowW*0.8, _windowH*0.1, _windowW*0.2, _windowH*0.9];
	var _pointArr = [];
	var _startendY = [];
	var _noiseVal = 0.005;
	var _lineColr = 0, _lineColg = 255, _lineColb = 255;
	var _lineCount = 4;

	if(window.matchMedia('(max-width:767px)').matches){
		_minx = -_cw;
		_maxx = _cw;
		_miny = 0;
		_maxy = _ch;
		_sy1 = 0.3;
		_sy2 = 0.7;
		_ey1 = 0.3
		_ey2 = 0.7;
		_pointInit = [_windowW*0.5, _windowH*0.5, _windowW*0.5, _windowH*0.5];
	}

	for (var g=0; g<_lineCount; g++) {
		_pointArr[g] = [];
		_startendY[g] = [];
	}

	p.setup = () => {
		p.createCanvas(p.windowWidth, p.windowHeight);
		p.noFill();
		setpoints();
		// p.background('rgba(0%,0%,0%,0.1)');
	};

	p.windowResized = () => {
		if(window.matchMedia('(min-width:768px)').matches){
			_windowW = p.windowWidth;
			_windowH = p.windowHeight;
			_cw = p.windowWidth;
			_ch = p.windowHeight;
			_endX = _windowW+10;
			_pointInit = [_windowW*0.8, _windowH*0.1, _windowW*0.2, _windowH*0.9];	
			p.resizeCanvas(p.windowWidth, p.windowHeight);
		}
	};

	p.draw = () => {
		// p.background('rgba(0%,0%,0%,0.1)');
		if(document.getElementById("lineAnimation") === null){
			p.remove();
		}
		p.clear();
		for (var h=0; h<_lineCount; h++) {
			p.stroke(_lineColr, _lineColg, _lineColb, 255);
			p.strokeWeight(.5);
			drawline(h);
			// drawpoint(h);
		}
		update();
	};
	
	function setpoints() {
		for (var h=0; h<_lineCount; h++) {
			for (var i=0; i<4 ; i++) {
				_pointArr[h][i] = _pointInit[i];
			}
			for (var i=4; i<8 ; i++) {
				_pointArr[h][i] = p.random(-_cw, _cw);
			}
			_startendY[h][0] = p.random(_ch*_sy1, _ch*_sy2);
			_startendY[h][1] = p.random(_ch*_ey1, _ch*_ey2);
		}
	}
	function drawline(h) {
		p.noFill();
		p.beginShape();
		p.vertex(_startX , _startendY[h][0]);
		p.bezierVertex(_pointArr[h][0],_pointArr[h][1], _pointArr[h][2],_pointArr[h][3], _endX, _startendY[h][1]);
		p.endShape();
	}
	function drawpoint(h) {
		p.noStroke();
		p.fill(255, 0, 0);
		p.ellipse(_pointArr[h][0],_pointArr[h][1], 5);
		p.noStroke();
		p.fill(255, 255, 0);
		p.ellipse(_pointArr[h][2],_pointArr[h][3], 5);
	}
	function update() {
		for (var h=0; h<_lineCount; h++) {
			let pnoiseArr = [];
			for (var i=0; i<4 ; i++) {
				pnoiseArr.push(p.noise(_pointArr[h][i+4])*4-1.89);
				if(i>1){
					_pointArr[h][i] += pnoiseArr[i];
				}else{
					_pointArr[h][i] -= pnoiseArr[i];
				}
				if(i%2===0){
					// x min to max
					if (_pointArr[h][i]<_minx) {_pointArr[h][i]=_minx; }
					if (_pointArr[h][i]>_maxx) {_pointArr[h][i]=_maxx; }	
				}else{
					// y min to max
					if (_pointArr[h][i]<_miny) {_pointArr[h][i]=_miny; }
					if (_pointArr[h][i]>_maxy) {_pointArr[h][i]=_maxy; }	
				}
				_pointArr[h][i+4] += _noiseVal;
			}
			for (var i=0; i<2 ; i++) {
				_startendY[h][i] += pnoiseArr[i];
				const chSy1 = _ch*_sy1;
				const chSy2 = _ch*_sy2;
				const chEy1 = _ch*_ey1;
				const chEy2 = _ch*_ey2;
				if(i===0){
					if (_startendY[h][i]<chSy1) {_startendY[h][i]=chSy1; }
					if (_startendY[h][i]>chSy2) {_startendY[h][i]=chSy2; }	
				}else{
					if (_startendY[h][i]<chEy1) {_startendY[h][i]=chEy1; }
					if (_startendY[h][i]>chEy2) {_startendY[h][i]=chEy2; }	
				}
			}

		}
	}

};

myp5 = new p5(p5init, 'lineAnimation');

}


export function p5Line_remove() {
	console.log(myp5.remove());
	myp5=null;
}


